import { Injectable } from "@angular/core";
import { IPHBulkCreateRequest } from "rl-common/components/bulk-grid/bulk-grid.models";
import { RlHttpClient } from "../../../common/services/rl-http-client.service";
import { AvailsSnapshot, IRightsExplorerCriteriaRequest } from "./rights-explorer.models";

@Injectable()
export class RightsExplorerHttpService {

	private _baseUrl = "/rights-explorer";

	constructor(private readonly _httpClient: RlHttpClient) {

	}

	getAvailsSnapshots(
		isAllSelected: boolean,
		selectedHashes: string[],
		deselectedHashes: string[],
		model: IRightsExplorerCriteriaRequest,
		offset: number,
		limit: number) {

		return this._httpClient
			.post(`${this._baseUrl}/avails-snapshots`)
			.body({
				isAllSelected,
				selectedHashes,
				deselectedHashes,
				availsRequest: {
					startDate: model.startDate,
					endDate: model.endDate,
					dim1ValueIds: model.dimIds[1],
					dim2ValueIds: model.dimIds[2],
					dim3ValueIds: model.dimIds[3],
					dim4ValueIds: model.dimIds[4],
					nonScopedFlags: model.nonScopedFlags,
					isExclusive: model.isExclusive,
					isWindowed: model.isWindowed,
					minDays: model.minDays,
					matchType: model.matchType,
					catalogIds: model.catalogIds,
					rightSetTemplateIds: model.rightSetTemplateIds,
					truncateWindow: model.truncateWindows,
					rollupDimId: model.rollup,
					catalogFacets: model.catalogFacets,
					dimFormatFlag: model.dimFormatFlag,
					showAvailable: model.showAvailable,
					showPartial: model.showPartial,
					showUnavailable: model.showUnavailable,
					collapseSequence: model.collapseSequence,
					rightsetHistoryTemplateIds: model.rightsetHistoryTemplateIds,
					summaryTemplateGroupCountType: model.summaryTemplateGroupCountType,
					rightsActionConditions: model.rightsActionConditions,
					gridViewColumns: null,
					offset: offset,
					limit: limit
				}
			})
			.fetch<AvailsSnapshot[]>();
	}

	createAvailsRights(dealRecordId: number, bulkCreatePackage: IPHBulkCreateRequest) {
		return this._httpClient
			.post(`${this._baseUrl}/create-avails-rights`)
			.body({
				dealRecordId: dealRecordId,
				bulkCreateRequest: bulkCreatePackage
			})
			.fetch<string>();
	}

	promptCriteria(promptText: string, browserNow: string, browserDateFormat: string) {
		return this._httpClient
			.post(`${this._baseUrl}/prompt-criteria`)
			.body({
				promptText,
				browserNow,
				browserDateFormat
			})
			.fetch<Pick<IRightsExplorerCriteriaRequest, "startDate" | "endDate" | "dimIds" | "isExclusive" | "showAvailable" | "showPartial" | "showUnavailable">>();
	}
}
